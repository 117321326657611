<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <label>Nome</label>
          <input
            type="text"
            class="form-control"
            maxlength="25"
            v-model="botao.nome"
            :disabled="$route.params.id"
          />
        </div>
        <div class="col-md-4">
          <label>Simbolo</label>
          <v-select
            :options="simbolos"
            :reduce="(simbolos) => simbolos.id"
            :label="'nome'"
            v-model="botao.simbolo_id"
            class="vselect"
            @input="setPhoto"
          ></v-select>  
        </div>
        <div class="col-md-4">
          <label>Setor</label>
          <select v-model="botao.setor_id" class="text-center form-control">
            <option v-for="setor in setores" :key="setor.id" :value="setor.id">
              {{ setor.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Classificação de risco</label>
          <select
            v-model="botao.classificacao_id"
            class="text-center form-control"
          >
            <option
              v-for="classificacao in classificacao"
              :key="classificacao.id"
              :value="classificacao.id"
            >
              {{ classificacao.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Prioridade</label>
          <select v-model="botao.preferencial" class="text-center form-control" >
            <option
              v-for="prioridade in prioridades"
              :key="prioridade.id"
              :value="prioridade.id"
            >
              {{prioridade.nome}}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <label>Prefixo</label>
          <input
            type="text"
            class="form-control"
            v-model="botao.prefixo_senha"
            oninput="this.value = this.value.toUpperCase()"
          />
        </div>
        <div class="col-md-3">
          <label>Cor</label>
          <input type="color" class="form-control" v-model="botao.cor" />
        </div>
        <div class="col-md-3">
          <label>Cor do texto</label>
          <input type="color" class="form-control" v-model="botao.cor_texto" />
        </div>
        <div class="col-md-3">
          <label>Ordem</label>
          <input
            type="number"
            class="form-control"
            min="0"
            max="100"
            maxlength="3"
            v-model="botao.ordem"
          />
        </div>
        <div class="col-md-3">
          <br>
          <label
            class="btn btn-primary w-100"
            :class="{disabled: !botao.concatena_prefixo}"
            style="cursor: pointer;"
          >
            <input type="checkbox" v-model="botao.concatena_prefixo" class="d-none">
            Concatenar Prefixo
          </label>
        </div>
        <div class="col-md-12 mt-1">
          <h4>Filho de</h4>
        </div>
        <div class="col-md-4" v-for="bot in botoes">
          <label
            class="btn btn-primary w-100"
            :class="{ disabled: !bot.ativo}"
            style="cursor: pointer"
            @click="pushBotao(bot)"
          >
            <input type="checkbox" v-model="bot.ativo" class="d-none" />
            {{ bot.nome }}
          </label>
        </div>
        <div class="col-md-12 mt-1">
          <label>
            Mensagem
            <span
              :style="
                botao.mensagem_senha.length > 140
                  ? 'color: red; font-weight: 500;'
                  : ''
              "
            >
              ({{ (botao.mensagem_senha.length - 150) * -1 }})
            </span>
          </label>
          <textarea
            cols="30"
            rows="3"
            maxlength="150"
            class="form-control"
            v-model="botao.mensagem_senha"
          ></textarea>
        </div>
        <div class="col-md-12">
          <div class="d-flex justify-content-center mt-2">
            <div class="col-md-6">
              <button
                class="btn w-100"
                :class="botao.simbolo_id ? 'd-flex justify-content-between align-items-center' : 'text-center'"
                style="height: 10vh; font-size: 4vh; border: 1px solid black"
                :style="'border: none; color:' + botao.cor_texto + '; background:' + botao.cor"
              >
                {{ botao.nome }}
                <img
                  :class="botao.simbolo_id ? '': 'd-none'"
                  :src="botao.url"
                  height="100%"
                >
              </button>
            </div>
          </div>
        </div>  
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/botoes" class="btn btn-back mr-1">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
          <a class="btn btn-add" @click="save">
            Salvar
            <i class="fa fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      botao: {
        classificacao_id: "",
        nome:             "",
        cor:              "",
        cor_texto:        "",
        ordem:            "",
        prefixo_senha:    "",
        mensagem_senha:   "",
        simbolo_id:       "",
      },
      botoes: [],
      classificacao: {},
      setores: {},
      simbolos: {},
      prioridades: [
        {
          id: 1,
          nome: "Comum",
        },
        {
          id: 2,
          nome: "Prioridade básica",
        },
        {
          id: 3,
          nome: "Alta prioridade",
        },  
      ]
    };
  },
  computed: {},
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("nome",              self.botao.nome);
      fd.append("setor_id",          self.botao.setor_id);
      fd.append("classificacao_id",  self.botao.classificacao_id);
      fd.append("preferencial",      self.botao.preferencial ? self.botao.preferencial : 1);
      fd.append("prefixo_senha",     self.botao.prefixo_senha);
      fd.append("cor",               self.botao.cor);
      fd.append("cor_texto",         self.botao.cor_texto);
      fd.append("ordem",             self.botao.ordem);
      fd.append("concatena_prefixo", self.botao.concatena_prefixo ? 1 : 0);
      fd.append("mensagem_senha",    self.botao.mensagem_senha);
      fd.append("simbolo_id",        self.botao.simbolo_id ? self.botao.simbolo_id : "");
      fd.append("botoes_pais",       JSON.stringify(self.botoes));

      if (self.botao.id) {
        fd.append("id", self.botao.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "botoes";

      self.$loading(true);

      if (self.botao.id) {
        api += "/" + self.botao.id;
      }

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.$loading(false);
          this.$router.push("/botoes");
        })
        .catch((error) => {
          self.$loading(false);

          self.$message(null, error.response.data, "error");
        });
    },
    getBotoes: function (id) {
      const self = this;
      const api = self.$store.state.api + "botoes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.botao = response.data.data[0];
          self.setLoader(20);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getClassificacoes: function () {
      const self = this;
      const api = self.$store.state.api + "classificacoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.classificacao = response.data.data;
          self.setLoader(20);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSimbolos: function () {
      const self = this;
      const api = self.$store.state.api + "simbolos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.simbolos = response.data.data;
          self.setLoader(10);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function () {
      const self = this;
      const api = self.$store.state.api + "setores?ativo=1&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;
          self.setLoader(20);

          if (!self.$route.params.id) {
            self.setLoader(10);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAllBotoes: function (id) {
      const self = this;
      const api = self.$store.state.api + "botoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          let i = 0;
          response.data.data.forEach(botao => {
            if(botao.id != self.$route.params.id){
              self.botoes[i] = botao;
              self.botoes[i].ativo = 0;
              i++;
            }
          });

          self.setLoader(20);
          if (!self.$route.params.id) {
            self.setLoader(10);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBotoesTotem: function(id) {
      const self = this;
      const api = self.$store.state.api + `botoes_totens?botao_filho_id=${id}&paginated=false`;

      axios
        .get(api)
        .then((response) => {
          let bot = [];
          response.data.data.forEach(item => {
            bot.push(item.botao_pai_id);
          });

          self.botoes.forEach(botao => {
            if(bot.includes(botao.id))
            {
              botao.ativo = 1;
            }
          });

          self.pushBotao();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    pushBotao: function(){
      setTimeout(() => {
        this.botoes.push();
      }, 50);
    },
    setPhoto: function(){
      const self = this;

      self.simbolos.forEach(simbolo => {
        console.log(simbolo);
        if(self.botao.simbolo_id === simbolo.id)
        {
          self.botao.url = simbolo.photo;
        }
      });

      console.log(self.botao.url);
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(10);

    self.getAllBotoes();
    self.getClassificacoes();
    self.getSetores();
    self.getSimbolos();

    if (id) {
      self.getBotoes(id);
      self.getBotoesTotem(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>