<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'atendimentosCrud'"
          crudName="Senhas"
          newText="Nova Senha"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="false"
          :enableEdit="false"
          :enableDelete="false"
          :enableActive="false"
          :enableView="false"
          :enableReset="false"
          :enableSenhaPath="true"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "AtendimentosTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: ["prefixo", "senha", "classificacao_id", "prioridade", "setor_id", "local_id", "user_id", "status", "actions"],
      tableData: ["prefixo", "senha", "classificacao_id", "prioridade", "setor_id", "local_id", "user_id", "status"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["prefixo", "senha", "classificacao_id", "setor_id", "local_id", "user_id"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10],
        headings: {
          id: "ID",
          prefixo: "Prefixo",
          senha: "Senha",
          classificacao_id: "Grupo prioritário",
          prioridade: "Prioridade",
          setor_id: "Setor",
          local_id: "Local",
          user_id: "Usuário",
          status: "Status",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {
          prefixo: function(h, row, index) {
            return row.atendimento.prefixo;
          },
          senha: function(h, row, index) {
            return row.atendimento.senha;
          },
          classificacao_id: function (h, row, index) {
            if(row.classificacao)
            {
              return (
                <span class="badge" style={{ background: row.classificacao.cor, color: row.classificacao.cor_texto }}>
                  {row.classificacao.nome}
                </span>
              );
            }

            return (<span class="badge badge-secondary">Indisponível</span>);
          },
          setor_id: function (h, row, index) {
            if(row.setor)
            {
              return (<span class="badge badge-secondary">{row.setor.nome}</span>);
            }

            return (<span class="badge badge-secondary">Indisponível</span>);
          },
          local_id: function (h, row, index) {
            if(row.local)
            {
              return (<span class="badge badge-secondary">{row.local.nome}</span>);
            }

            return (<span class="badge badge-secondary">Não atendido</span>);
          },
          user_id: function (h, row, index) {

            if(row.user)
            {
              return (<span class="badge badge-secondary">{row.user.name}</span>);
            }
            return (<span class="badge badge-secondary">Não atendido</span>);
          },
          prioridade: function (h, row, index) {
            switch(row.prioridade)
            {
              case 0:
                return <span class="badge badge-danger">{row.prioridade_f}</span>
              case 1:
                return <span class="badge badge-primary">{row.prioridade_f}</span>
              case 2:
                return <span class="badge badge-secondary">{row.prioridade_f}</span>
              case 3:
                return <span class="badge badge-warning">{row.prioridade_f}</span>
            }
            return (<span class="badge badge-secondary">Indisponível</span>);
          },
          status: function (h, row, index) {

            switch(row.status)
            {
              case 0: case 4:
                return <span class="badge badge-danger">{row.status_text}</span>
              case 1:
                return <span class="badge badge-secondary">{row.status_text}</span>
              case 2:
                return <span class="badge badge-primary">{row.status_text}</span>
              case 3:
                return <span class="badge badge-success">{row.status_text}</span>
              case 8: case 9:
                return <span class="badge badge-info">{row.status_text}</span>
              default:
                return <span class="badge badge-secondary">{row.status_text}</span>;
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ['atendimento', 'classificacao', 'local', 'setor', 'user'];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "atendimento_setor/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    query: function (query) {
      let columns = {
        prefixo: "atendimento[prefixo]",
        senha: "atendimento[senha]",
        prioridade: "prioridade",
        classificacao_id: "classificacao[nome]",
        setor_id: "setor[nome]",
        local_id: "local[nome]",
        user_id: "user[name]",
        status: "status",
      };
      let filters = "&orderByDesc=id&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>