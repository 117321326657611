<template>
  <div>
    <div class="main-content p-1" v-if="loader >= 100">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  class="d-flex justify-content-between flex-column col-xl-7 col-21"
                >
                  <div class="row">
                    <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                      <div class="d-flex justify-content-center align-items-center" :style="'width :' + (img + 50) + 'px;'">
                        <span
                          class="b-avatar badge-light-danger rounded"
                          :style="
                            'min-width:' + img + 'px; height:' + img + 'px;'
                          "
                        >
                          <span class="b-avatar-img">
                            <img
                              src="@/assets/images/avatars/default.png"
                              alt="avatar"
                              class="clocks"
                            />
                          </span>
                        </span>
                      </div>
                      <span
                        class="btn badge mt-1 hover-white"
                        :class="senha.prioridade ? 'badge-secondary' : 'badge-danger'"
                        data-toggle="modal"
                        data-target="#changePrioridade"
                      >
                        {{ senha.prioridade_f }}
                      </span>
                      <!-- Modal -->
                      <div class="modal fade" id="changePrioridade" tabindex="-1" role="dialog" aria-labelledby="changePrioridadeLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="changePrioridadeLabel">Alterar prioridade</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <select v-model="prioridade" class="form-control">
                                <option :value="0" :key="0" :selected="senha.prioridade === 0 ? 'true': 'false'">Ausente</option>
                                <option :value="1" :key="1" :selected="senha.prioridade === 1 ? 'true': 'false'">Comum</option>
                                <option :value="2" :key="2" :selected="senha.prioridade === 2 ? 'true': 'false'">Prioritário</option>
                                <option :value="3" :key="3" :selected="senha.prioridade === 3 ? 'true': 'false'">Prioritário +80</option>
                              </select>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                              <button type="button" class="btn btn-success" data-dismiss="modal" @click="changePrioridade">Confirmar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ml-1 ml-sm-0">
                      <h4 class="mt-1 mt-sm-0">
                        <span>
                          <h3>
                            {{ senha.atendimento.prefixo 
                            }}{{ senha.atendimento.senha }}
                            <button
                              class="btn btn-add btn-sm"
                              data-toggle="modal"
                              data-target="#subsenha"
                              @click="getSenhas()"
                              v-show="configuracao.sub_senha == 1"
                            >
                              Adicionar Subsenhas
                            </button>
                          </h3>
                          <div
                            class="modal fade"
                            id="subsenha"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="subsenhaTitle"
                            aria-hidden="true"
                            style="overflow: hidden"
                          >
                            <div class="modal-dialog modal-lg" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="subsenhaTitle">
                                    Subsenhas
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  class="modal-body"
                                  style="font-size: 11.5pt"
                                >
                                  As seguintes senhas são subsenhas dessa
                                  senha:<br />
                                  <table class="table table-bordered">
                                    <tr>
                                      <th width="30%">Senha</th>
                                      <th width="50%">Nome</th>
                                      <th width="20%">Ações</th>
                                    </tr>
                                    <tr v-for="atendimento in atendimentos">
                                      <td>
                                        <b
                                          >{{ atendimento.prefixo
                                          }}{{ atendimento.senha }}</b
                                        >
                                      </td>
                                      <td>
                                        <b>{{
                                          atendimento.pessoa
                                            ? atendimento.pessoa.nome
                                            : "Não informado"
                                        }}</b>
                                      </td>
                                      <td>
                                        <button class="btn btn-delete btn-crud" @click="removeSubsenha(atendimento.id)">
                                          <i class="fas fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </table>
                                  <br />
                                  Adicionar senhas existentes como subsenhas:
                                  <v-select
                                    :options="formattedAtendimentos"
                                    :reduce="
                                      (allAtendimentos) => allAtendimentos.id
                                    "
                                    :label="'label'"
                                    v-model="subsenha"
                                    class="vselect"
                                  ></v-select>
                                  <br>
                                  Ou gere uma nova senha: <br><br>
                                  <button class="btn btn-sm btn-outline-primary" @click="abreSubSenha">Abrir nova senha</button>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="addSubsenha()"
                                  >
                                    Salvar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      </h4>
                      <h4 class="mt-0">
                        <span>
                          {{
                            pessoa.nome_social
                              ? pessoa.nome_social
                              : "Não informado"
                          }} </span
                        ><br />
                      </h4>
                      <h6>
                        <span
                          >Entrada
                          {{ senha.atendimento.data_inicio_f }}
                        </span>
                      </h6>
                      <span class="card-text">
                        {{ pessoa.diffIdade }}
                      </span>
                      <span
                        class="badge mr-1 fs-14 mt-0 mb-1"
                        :class="'badge'"
                        :style="'background:' + senha.classificacao.cor"
                      >
                        {{ senha.classificacao.nome ? senha.classificacao.nome : "Não informado"}}
                      </span>
                      <span class="card-text mt-0">
                        <button class="btn btn-add" @click="pessoaModal()">
                          Editar Informações
                          <i class="fas fa-user-edit"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div
                    class="modal fade"
                    id="pessoaModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="title"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="title">
                            Informações da {{ configuracao.tipo_pessoa}}
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-left">
                          <div class="row">
                            <div class="col-md-12">
                              <h6><b>Documentos:</b></h6>
                            </div>
                            <div class="col-md-6 mb-1">
                              <label><b>CPF</b></label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="pessoa.cpf"
                                  v-mask="'999.999.999-99'"
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-primary"
                                    type="button"
                                    @click="getCpf()"
                                  >
                                    <i class="fas fa-search"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-6 mb-1"
                              v-show="configuracao.cns == 1"
                            >
                              <label><b>CNS</b></label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="pessoa.cns"
                              />
                            </div>
                            <div class="col-md-12 mt-1">
                              <h6>
                                <b>Filiação (biológica):</b>
                              </h6>
                            </div>
                            <div class="col-md-12 mb-1">
                              <label><b>Nome do pai</b></label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="pessoa.nome_pai"
                              />
                            </div>
                            <div class="col-md-12 mb-1">
                              <label><b>Nome da mãe</b></label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="pessoa.nome_mae"
                              />
                            </div>
                            <div class="col-md-12 mt-1">
                              <h6><b>Informações pessoais:</b></h6>
                            </div>
                            <div class="col-md-12 mb-1">
                              <label><b>Nome</b></label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="pessoa.nome_social"
                              />
                            </div>

                            <div class="col-md-12 mb-1">
                              <label><b>Endereço</b></label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="pessoa.endereco"
                              />
                            </div>
                            <div class="col-md-4 mb-1">
                              <label><b>Data de nascimento</b></label>
                              <input
                                type="date"
                                class="form-control"
                                v-model="pessoa.data_nascimento_form"
                              />
                            </div>
                            <div class="col-md-4 mb-1">
                              <label><b>Telefone</b></label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="pessoa.telefone"
                                v-mask="'(99) 99999-9999'"
                              />
                            </div>
                            <div class="col-md-4 mb-1">
                              <label><b>Sexo</b></label>
                              <select
                                v-model="pessoa.sexo"
                                class="text-center form-control"
                              >
                                <option :value="'M'">Masculino</option>
                                <option :value="'F'">Feminino</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                            @click="pessoa = []"
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            class="btn btn-success"
                            data-dismiss="modal"
                            @click="savePessoa()"
                          >
                            Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-5 col-12">
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <i class="fas fa-venus-mars mr-75"></i>
                        <span class="font-weight-bold">Sexo</span>
                      </th>
                      <td class="pb-50">
                        {{
                          pessoa.sexo
                            ? pessoa.sexo === "M"
                              ? "Masculino"
                              : "Feminino"
                            : "Não Informado"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <i class="fas fa-id-card mr-75"></i>
                        <span class="font-weight-bold">CNS</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{ pessoa.cns ? pessoa.cns : "Não informado" }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <i class="fas fa-calendar-alt mr-75"></i>
                        <span class="font-weight-bold">Nascimento</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{
                          pessoa.data_nascimento
                            ? pessoa.data_nascimento_f
                            : "Não informado"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14px"
                          height="14px"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="mr-75 feather feather-phone"
                        >
                          <path
                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                          ></path>
                        </svg>
                        <span class="font-weight-bold">Telefone</span>
                      </th>
                      <td>
                        {{
                          pessoa.telefone ? pessoa.telefone : "Não informado"
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-3 mt-1 mt-md-0">
                  <div class="d-flex align-items-top clocks">
                    <span class="b-avatar badge-light-secondary rounded">
                      <i
                        class="far fa-clock rotating"
                        style="font-size: 18px"
                      ></i>
                    </span>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ tempoUnidade }}
                      </h5>
                      <small>Tempo de espera na unidade</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mt-1 mt-md-0">
                  <div class="d-flex align-items-top clocks">
                    <span class="b-avatar badge-light-success rounded">
                      <i
                        class="far fa-clock rotating"
                        style="font-size: 18px"
                      ></i>
                    </span>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ tempoSetor }}
                      </h5>
                      <small>Tempo de espera neste setor</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mt-1 mt-md-0">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-top clocks">
                      <span class="b-avatar badge-light-primary rounded">
                        <i
                          class="far fa-clock rotating"
                          style="font-size: 18px"
                        ></i>
                      </span>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ tempoChamada }}
                        </h5>
                        <small>Tempo desde a ultima chamada</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mt-1 mt-md-0">
                  <div class="d-flex align-items-top clocks">
                    <span class="b-avatar badge-light-warning rounded">
                      <i
                        class="far fa-clock rotating"
                        style="font-size: 18px"
                      ></i>
                    </span>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ tempoInicio }}
                      </h5>
                      <small>Tempo desde o começo do atendimento</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="anotacoes.length">
          <div class="card p-0">
            <div class="card-header text-white bg-primary mb-1 p-1">
              <b>Observações ({{ anotacoes.length }})</b>
            </div>
            <div
              class="card-body row d-flex justify-content-center align-items-center"
            >
              <div
                class="col-12 col-md-1 mt-1 mt-md-0 d-flex justify-content-center align-items-center"
              >
                <button
                  class="btn btn-add"
                  v-if="anotacoes[anotacao - 1]"
                  @click="anotacao--"
                >
                  <i class="fas fa-arrow-left"></i>
                </button>
              </div>
              <div class="col-12 col-md-10 mt-1 mt-md-0">
                <div class="row text-center">
                  <div class="col-12">
                    <b>{{ anotacoes[anotacao].user.name }}</b
                    >&nbsp;({{ anotacoes[anotacao].created_at_f }})
                  </div>
                  <div
                    class="col-12 mt-1"
                    v-html="anotacoes[anotacao].texto"
                  ></div>
                </div>
              </div>
              <div
                class="col-12 col-md-1 mt-1 mt-md-0 d-flex justify-content-center align-items-center"
              >
                <button
                  class="btn btn-add"
                  v-if="anotacoes[anotacao + 1]"
                  @click="anotacao++"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row d-flex justify-content-around">
                <div
                  class="col-md-2 d-flex justify-content-center my-1 p-0"
                  v-if="!senha.data_inicio"
                  :class="senha.data_inicio ? '' : 'fadeOut'"
                >
                  <button
                    class="btn btn-success col-11 clocks"
                    @click="iniciar()"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fas fa-play p-1" style="font-size: 5vh"></i><br />
                    Iniciar Atendimento
                  </button>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center my-1 p-0"
                  v-if="!senha.data_inicio"
                  :class="senha.data_inicio ? '' : 'fadeOut'"
                >
                  <button
                    class="btn btn-primary col-11 clocks"
                    :disabled="cooldown"
                    @click="chamarNovamente()"
                  >
                    <i
                      id="iconChamada"
                      class="fas fa-sync-alt"
                      style="font-size: 5vh"
                      v-show="!contagemRegressiva"
                    ></i>
                    <h1 style="font-size: 5vh; color: #ffffff;"
                      v-show="contagemRegressiva && cooldown">{{ contagemRegressiva }}</h1>
                    <br v-show="!(contagemRegressiva && cooldown)" /><br />
                    Chamar novamente
                  </button>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center my-1 p-0"
                  v-if="senha.data_inicio"
                >
                  <button
                    class="btn btn-warning col-11 clocks"
                    data-toggle="modal"
                    data-target="#agenda"
                  >
                    <i class="fas fa-calendar p-1" style="font-size: 5vh"></i
                    ><br />
                    Agendar Consulta
                  </button>
                  <div
                    class="modal fade"
                    id="agenda"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="title"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header bg-warning">
                          <h5 class="modal-title text-light" id="title">
                            Agendar Consulta
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-center p-1">
                          <div class="row">
                            <div class="col-md-6">
                              <label>CPF</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="pessoa.cpf"
                                  v-mask="'999.999.999-99'"
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-warning"
                                    type="button"
                                    @click="getCpf()"
                                  >
                                    <i class="fas fa-search"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <label>CNS</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="agendamento.cns"
                                v-mask="'99999999999 9999'"
                              />
                            </div>
                            <div class="col-md-6">
                              <label>Setor</label>
                              <select
                                v-model="agendamento.setor_id"
                                class="text-center form-control"
                              >
                                <option
                                  v-for="setor in allSetores"
                                  :key="setor.id"
                                  :value="setor.id"
                                >
                                  {{ setor.nome ? setor.nome : "Não informado"}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-6">
                              <label>Data</label>
                              <input
                                type="datetime-local"
                                class="form-control"
                                v-model="agendamento.data_agendado"
                              />
                            </div>
                            <div class="col-md-12">
                              <label>Nome</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="agendamento.nome"
                              />
                            </div>
                            <div class="col-md-12">
                              <label>Descrição</label>
                              <textarea
                                type="text"
                                class="form-control"
                                v-model="agendamento.descricao"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            class="btn btn-warning"
                            data-dismiss="modal"
                            @click="agendarConsulta()"
                          >
                            Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center my-1 p-0"
                  v-if="senha.data_inicio"
                >
                  <button
                    class="btn btn-primary col-11 clocks"
                    data-toggle="modal"
                    data-target="#obs"
                  >
                    <i class="fas fa-plus p-1" style="font-size: 5vh"></i><br />
                    Adicionar observação
                  </button>
                  <div
                    class="modal fade"
                    id="obs"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="title"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header bg-primary">
                          <h5 class="modal-title text-light" id="title">
                            Adicionar observação
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-center p-1">
                          <div class="row">
                            <div class="col-12">
                              <textarea
                                class="form-control"
                                rows="5"
                                v-model="text"
                                placeholder="Digite a observação aqui..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            @click="saveObservacao()"
                          >
                            Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 d-flex justify-content-center my-1 p-0">
                  <button
                    class="btn btn-danger col-11 clocks"
                    data-toggle="modal"
                    data-target="#evasao"
                  >
                    <i class="fas fa-ban p-1" style="font-size: 5vh"></i><br />
                    Ausente
                  </button>
                  <div
                    class="modal fade"
                    id="evasao"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="title"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header bg-danger">
                          <h5 class="modal-title text-light" id="title">
                            Ausente
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-center p-1">
                          <button
                            class="col-md-12 btn btn-danger mt-1"
                            @click.once="ausente(0)"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Ausente
                          </button>
                          <button
                            class="col-md-12 btn btn-danger mt-1"
                            @click.once="ausente(1)"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Evasão
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center my-1 p-0"
                  v-if="senha.data_inicio"
                >
                  <button
                    class="btn btn-info col-11 clocks"
                    data-toggle="modal"
                    data-target="#encaminhar"
                  >
                    <i class="fas fa-share p-1" style="font-size: 5vh"></i
                    ><br />
                    Encaminhar
                  </button>
                  <div
                    class="modal fade"
                    id="encaminhar"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="title"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header bg-info">
                          <h5 class="modal-title text-light" id="title">
                            Encaminhar
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-center p-1">
                          <div class="row">
                            <div
                              :class="
                                'mt-1 col-md-' +
                                (setores.length > 5 ? '6' : '12')
                              "
                              v-for="setor in setores"
                              :key="setor.id"
                            >
                              <button
                                class="btn btn-info w-100"
                                @click.once="encaminhar(setor.setor.id)"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                {{ setor.setor ? setor.setor.nome : "Não informado"}}
                              </button>
                            </div>
                            <div class="col-12">
                              <hr />
                            </div>
                            <div
                              :class="
                                'col-md-' + (setores.length > 5 ? '6' : '12')
                              "
                            >
                              <button
                                class="btn btn-info w-100"
                                @click.once="espera()"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                Espera
                              </button>
                            </div>
                            <div
                              :class="
                                'mt-1 col-md-' +
                                (setores.length > 5 ? '6' : '12')
                              "
                            >
                              <button
                                class="btn btn-info w-100"
                                @click.once="medicacao()"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                Medicação
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center my-1 p-0"
                  v-if="senha.data_inicio"
                >
                  <button
                    class="btn btn-secondary col-11 clocks"
                    data-toggle="modal"
                    data-target="#finaliza-senha"
                  >
                    <i class="fas fa-stopwatch p-1" style="font-size: 5vh"></i
                    ><br />
                    Finalizar senha
                  </button>
                  <div
                    class="modal fade"
                    id="finaliza-senha"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="title"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header bg-secondary">
                          <h5 class="modal-title text-light" id="title">
                            Finalizar Senha
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body text-center p-1">
                          <div class="row">
                            <div
                              :class="
                                'mt-1 col-md-' +
                                (finalizacao.length > 5 ? '6' : '12')
                              "
                              v-for="(motivo, index) in finalizacao"
                              :key="index"
                            >
                              <button
                                class="btn btn-secondary w-100"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click.once="finalizar(motivo.id)"
                              >
                                {{ motivo.nome ? motivo.nome : "Não informado" }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "imageUpload",
  data() {
    return {
      cooldown: false,
      contagemRegressiva: 0,
      img: ((screen.width + screen.height) / 2) * 0.1,
      text: "",
      loader: 0,
      atual: [],
      anotacoes: [],
      anotacao: 0,
      classificacao: [],
      finalizacao: [],
      agendamento: {
        setor_id: "",
        nome: "",
        cns: "",
        cpf: "",
        data_agendado: "",
      },
      pessoa: {
        nome: "",
        nome_social: "",
        nome_mae: "",
        nome_pai: "",
        cns: "",
        cpf: "",
        sexo: "",
        data_nascimento: "",
        telefone: "",
        endereco: "",
      },
      allSetores: [],
      setores: [],
      senha: {
        atendimento: {
          prefixo: "-",
          senha: "----",
        },
        classificacao: {
          nome: "Sem classificação",
          cor: "#666",
        },
      },
      tempoUnidade: "--:--:--",
      tempoSetor: "--:--:--",
      tempoInicio: "--:--:--",
      tempoChamada: "--:--:--",
      atendimentos: [],
      allAtendimentos: [],
      subsenha: "",
      configuracao: [],
      prioridade: "",
    };
  },
  computed: {
    formattedAtendimentos() {
      return this.allAtendimentos.map((atendimento) => {
        return {
          ...atendimento,
          label: `${atendimento.prefixo}${atendimento.senha}`,
        };
      });
    },
  },
  methods: {
    getSenha: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "atendimento_setor/" +
        id +
        "?with[]=atendimento&with[]=classificacao&whereNull=data_fim";

      axios
        .get(api)
        .then((response) => {
          self.senha = response.data.data[0];

          if (!self.senha.classificacao) {
            self.senha.classificacao = {
              nome: "Sem classificação",
              cor: "#666",
            };
          }

          if (!self.senha.data_inicio) {
            self.timerUnidade(self.senha.atendimento.data_inicio_diff);
            self.timerSetor(self.senha.data_entrada_diff);
            self.timerChamada(self.senha.data_ultima_chamada_diff);
          } else {
            self.timerUnidade(self.senha.atendimento.data_inicio_diff);
            self.timerSetor(self.senha.data_entrada_diff);
            self.timerInicio(self.senha.data_inicio_diff);
            self.timerChamada(self.senha.data_ultima_chamada_diff);
          }

          self.prioridade = self.senha.prioridade;

          self.getSetorAtual();
          self.getAnotacoes();

          if (self.senha.atendimento.pessoa_id) {
            self.getPessoa(self.senha.atendimento.pessoa_id);
          }
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAnotacoes: function () {
      const self = this;
      const api =
        self.$store.state.api + `anotacoes?paginated=false&orderByDesc=created_at&with[]=user&atendimento_id=${self.senha.atendimento.id}`;

      axios
        .get(api)
        .then((response) => {
          self.anotacoes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    chamarNovamente: function () {
      const self = this;
      const api = self.$store.state.api + "chamar_novamente/" + self.$route.params.id;

      self.cooldown = true;

      clearInterval(self.contadorChamada);

      $({ deg: 0 }).animate(
        { deg: 720 },
        {
          step: function (now, fx) {
            $("#iconChamada").css({
              transform: "rotate(" + now + "deg)",
            });
          },
        }
      );

      setTimeout(() => {
        self.contagemRegressiva = 5;

        let contagem = setInterval(() => {
          self.contagemRegressiva--;
        }, 1000);

        setTimeout(() => {
          self.cooldown = false;
          clearInterval(contagem);
        }, 5000);
      }, 1000);

      self.timerChamada(0);

      axios
        .post(api)
        .then(() => {
          self.$message(null, "Senha chamada", "info");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    classificar: function () {
      $("#classificacaoModal").modal("toggle");
    },
    pessoaModal: function () {
      $("#pessoaModal").modal("toggle");
    },
    getClassificacao: function () {
      const self = this;
      const api = self.$store.state.api + "classificacoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.classificacao = response.data.data;
          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    classifica: function () {
      const self = this;
      const api = self.$store.state.api + "classifica/" + self.$route.params.id;

      axios
        .post(api, { classificacao_id: self.senha.classificacao_id })
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getSenha(self.$route.params.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPessoa: function (id) {
      const self = this;
      const api = self.$store.state.api + "pessoas/" + id;

      axios
        .get(api)
        .then((response) => {
          self.pessoa = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("cpf",             self.pessoa.cpf                  ? self.pessoa.cpf                  : "");
      fd.append("cns",             self.pessoa.cns                  ? self.pessoa.cns                  : "");
      fd.append("nome",            self.pessoa.nome_social          ? self.pessoa.nome_social          : "");
      fd.append("nome_social",     self.pessoa.nome_social          ? self.pessoa.nome_social          : "");
      fd.append("nome_mae",        self.pessoa.nome_mae             ? self.pessoa.nome_mae             : "");
      fd.append("nome_pai",        self.pessoa.nome_pai             ? self.pessoa.nome_pai             : "");
      fd.append("endereco",        self.pessoa.endereco             ? self.pessoa.endereco             : "");
      fd.append("telefone",        self.pessoa.telefone             ? self.pessoa.telefone             : "");
      fd.append("data_nascimento", self.pessoa.data_nascimento_form ? self.pessoa.data_nascimento_form : "");
      fd.append("sexo",            self.pessoa.sexo                 ? self.pessoa.sexo                 : "");

      if (self.pessoa.id) {
        fd.append("id", self.pessoa.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    savePessoa: function () {
      const self = this;
      var api = self.$store.state.api + "pessoas/";

      self.$loading(true);

      if(self.pessoa.id !== undefined)
      {
        api += self.pessoa.id;
      }

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.pessoa.id = response.data;

          self.addPessoa();
          self.getPessoa(response.data.id);
          self.$loading(true);
        })
        .catch((error) => {
          self.$loading(true);
          self.$message(null, error.response.data, "error");
        });
    },
    saveObservacao: function () {
      const self = this;
      const api = self.$store.state.api + "anotacoes";

      self.$loading(true);

      axios
        .post(api, {
          usuario_id: self.$store.state.user.id,
          atendimento_id: self.senha.atendimento.id,
          texto: self.text,
        })
        .then(() => {
          self.$loading(false);
          self.getAnotacoes();
          self.text = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    addPessoa: function () {
      const self = this;
      const api =
        self.$store.state.api + "addPessoa/" + self.senha.atendimento.id;

      axios
        .post(api, { pessoa_id: self.pessoa.id })
        .then(() => {})
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetorAtual: function () {
      const self = this;
      const api = self.$store.state.api + "setores?ativo=1&id=" + self.$store.state.user.setor;

      axios
        .get(api)
        .then((response) => {
          self.atual =
            response.data.data.length > 0 ? response.data.data[0] : [];
          self.getSetores();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function () {
      const self = this;
      var api = self.$store.state.api + "fluxo_setores?paginated=false&with[]=setor&ativo=1&setor_original_id=" + self.$store.state.user.setor;

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;

          self.getAllSetores();

          self.setLoader(12);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAllSetores: function () {
      const self = this;
      var api = self.$store.state.api + "setores?ativo=1&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.allSetores = response.data.data;
          self.setLoader(13);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getFinalizacao: function () {
      const self = this;
      const api = self.$store.state.api + "finalizacoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.finalizacao = response.data.data;
          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    iniciar: function () {
      const self = this;
      const api = self.$store.state.api + "iniciar/" + self.$route.params.id;

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.timerInicio(0);
          self.senha.data_inicio = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    ausente: function (tipo) {
      const self = this;

      self.$loading(true);

      if (tipo) {
        var rota = "evasao";
      } else {
        var rota = "ausente";
      }
      const api = self.$store.state.api + rota + "/" + self.$route.params.id;

      axios
        .post(api)
        .then(() => {
          self.$loading(false);
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          this.$router.push("/chamador");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    encaminhar: function (id) {
      const self = this;
      const api = self.$store.state.api + "encaminhar/" + self.$route.params.id;

      self.$loading(true);

      axios
        .post(api, { setor_id: id })
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.$loading(false);
          this.$router.push("/chamador");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    espera: function () {
      const self = this;
      const api = self.$store.state.api + "espera/" + self.$route.params.id;
      self.$loading(true);

      axios
        .post(api)
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.$loading(false);
          this.$router.push("/chamador");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    medicacao: function () {
      const self = this;
      const api = self.$store.state.api + "medicacao/" + self.$route.params.id;
      self.$loading(true);

      axios
        .post(api)
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.$loading(false);
          this.$router.push("/chamador");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    finalizar: function (id) {
      const self = this;
      const api = self.$store.state.api + "finalizar/" + self.$route.params.id;
      self.$loading(true);

      axios
        .post(api, { finalizacao_id: id })
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.$loading(false);
          this.$router.push("/chamador");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    timerSetor: function (tempo_inicial) {
      const self = this;

      var segundos = tempo_inicial;
      var minutos = 0;
      var horas = 0;

      var contadorSetor = setInterval(function () {
        segundos++;

        if (segundos > 60) {
          minutos = Math.floor(segundos / 60);
        }

        if (minutos > 60) {
          horas = Math.floor(minutos / 60);
        }

        self.tempoSetor =
          horas.toString().padStart(2, "0") +
          ":" +
          (minutos % 60).toString().padStart(2, "0") +
          ":" +
          (segundos % 60).toString().padStart(2, "0");
      }, 1000);
    },
    timerInicio: function (tempo_inicial) {
      const self = this;

      var segundos = tempo_inicial;
      var minutos = 0;
      var horas = 0;

      var contadorInicio = setInterval(function () {
        segundos++;

        if (segundos > 60) {
          minutos = Math.floor(segundos / 60);
        }

        if (minutos > 60) {
          horas = Math.floor(minutos / 60);
        }

        self.tempoInicio = horas.toString().padStart(2, "0") + ":" + (minutos % 60).toString().padStart(2, "0") + ":" + (segundos % 60).toString().padStart(2, "0");
      }, 1000);
    },
    timerUnidade: function (tempo_inicial) {
      const self = this;

      var segundos = tempo_inicial;
      var minutos = 0;
      var horas = 0;

      var contadorUnidade = setInterval(function () {
        segundos++;

        if (segundos > 60) {
          minutos = Math.floor(segundos / 60);
        }

        if (minutos > 60) {
          horas = Math.floor(minutos / 60);
        }

        self.tempoUnidade =
          horas.toString().padStart(2, "0") +
          ":" +
          (minutos % 60).toString().padStart(2, "0") +
          ":" +
          (segundos % 60).toString().padStart(2, "0");
      }, 1000);
    },
    timerChamada: function (tempo_inicial) {
      const self = this;

      var segundos = tempo_inicial;
      var minutos = 0;
      var horas = 0;

      self.contadorChamada = setInterval(function () {
        segundos++;

        if (segundos > 60) {
          minutos = Math.floor(segundos / 60);
        }

        if (minutos > 60) {
          horas = Math.floor(minutos / 60);
        }

        self.tempoChamada =
          horas.toString().padStart(2, "0") +
          ":" +
          (minutos % 60).toString().padStart(2, "0") +
          ":" +
          (segundos % 60).toString().padStart(2, "0");
      }, 1000);
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
    getCpf: function () {
      const self = this;

      if (self.pessoa.cpf.split("")[13] !== "_") {
        const api = self.$store.state.api + "get-cpf";

        axios
          .post(api, {
            documento: self.pessoa.cpf.replace("-", "").replaceAll(".", ""),
            tipoDocumento: "CPF",
          })
          .then((response) => {
            if(response.data.erro)
            {
              return self.$message("Atenção!", "CPF não encontrado", "warning");
            }

            self.pessoa.nome                  = response.data.nome;
            self.pessoa.nome_social           = response.data.nome;
            self.pessoa.nome_mae              = response.data.nomeMae;
            self.pessoa.nome_pai              = response.data.nomePai;
            self.pessoa.cns                   = response.data.codigoCNS;
            self.pessoa.cpf                   = response.data.cpf;
            self.pessoa.sexo                  = response.data.sexo;
            self.pessoa.data_nascimento       = response.data.dataNascimento;
            self.pessoa.data_nascimento_form  = response.data.data_nascimento_form;
            self.pessoa.data_nascimento_f     = response.data.data_nascimento_f;
            self.pessoa.telefone              = response.data.telefone;
            self.pessoa.endereco              = response.data.endereco;

            self.agendamento.nome             = response.data.nome;
            self.agendamento.cns              = response.data.codigoCNS;
            self.agendamento.cpf              = response.data.cpf;

            self.$message("Pessoa encontrada", response.data.nome, "success");
          })
          .catch(() => {
            self.$message("Atenção!", "CPF não encontrado", "warning");
          });
      }
    },
    agendarConsulta: function () {
      const self = this;
      const api = self.$store.state.api + "agendamentos/";

      self.$loading(true);
      self.agendamento.nome = self.pessoa.nome;
      self.agendamento.status = 1;

      axios
        .post(api, self.agendamento)
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSenhas: function () {
      const self = this;
      const api = self.$store.state.api + "atendimentos?paginated=false&with[]=pessoa&atendimento_id_pai=" + self.$route.params.id;

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.atendimentos = response.data.data;

          self.getAllAtendimentos();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAllAtendimentos: function () {
      const self = this;
      const api = self.$store.state.api + "atendimentos?paginated=false&whereNull=data_fim";

      axios
        .get(api)
        .then((response) => {
          self.allAtendimentos = response.data.data;

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    addSubsenha: function () {
      const self = this;
      const api = self.$store.state.api + "add-subsenha/" + self.subsenha;
      self.$loading(true);
      self.subsenhas = "";

      axios
        .post(api, { atendimento_id: self.$route.params.id })
        .then(() => {
          self.getSenhas();

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    removeSubsenha: function (id) {
      const self = this;
      const api = self.$store.state.api + "remove-subsenha/" + id;
      self.$loading(true);

      axios
        .post(api)
        .then(() => {
          self.getSenhas();

          self.subsenhas = "";

          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    abreSubSenha: function () {
      const self = this;
      const api = self.$store.state.api + "cria_senha";

      axios
        .post(api, {
          botao_id: self.senha.atendimento.botao_id,
          cpf: "",
          prioridade: self.senha.prioridade,
          documento: [],
          impressora: "",
          setor_id: self.senha.setor_id,
        })
        .then((response) => {
          self.subsenha = response.data.id;

          self.addSubsenha();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    changePrioridade: function() {
      const self = this;
      const api = self.$store.state.api + `troca-prioridade/${self.senha.id}`;
      self.$loading(true);

      axios
        .post(api, {prioridade: self.prioridade})
        .then(() => {
          self.senha.prioridade = self.prioridade;
          self.getSenha(self.senha.id);

          self.$message("Sucesso", `Informações guardadas com sucesso`, "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;
    self.$loading(true);

    self.setLoader(25);

    self.getClassificacao();
    self.getFinalizacao();
    self.getConfiguracao();

    if (id) {
      self.getSenha(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.clocks:hover {
  transform: scale(1.05);
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.hover-white:hover{
  color: #eee;
}
.fadeOut {
  display: none;
  transition: opacity 1s ease-in-out;
}
</style>