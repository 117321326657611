import Crud from '../views/Unidade/Crud'
import Entity from '../views/Unidade/Add'

let routes = {
    path: '/unidades',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/unidades/add',
            name: 'NewUnidade',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Unidade',
                breadcrumb: [
                    {
                        text: 'Unidades',
                        active: false,
                        to: '/unidades',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/unidades/edit/:id?',
            name: 'EditUnidade',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Unidade',
                breadcrumb: [
                    {
                        text: 'Unidades',
                        active: false,
                        to: '/unidades',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/unidades',
            name: 'list-unidades',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Unidades',
                breadcrumb: [
                    {
                        text: 'Unidades',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
