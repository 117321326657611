import { render, staticRenderFns } from "./Path.vue?vue&type=template&id=2937aecb&scoped=true&"
import script from "./Path.vue?vue&type=script&lang=js&"
export * from "./Path.vue?vue&type=script&lang=js&"
import style0 from "./Path.vue?vue&type=style&index=0&id=2937aecb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2937aecb",
  null
  
)

export default component.exports