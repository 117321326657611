import Entity from '../views/Indicador/Indicador'

let routes = {
    path: '/indicadores',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/indicadores',
            name: 'indicadores',
            component: Entity,
            meta: {
                pageTitle: 'Indicadores',
                breadcrumb: [
                    {
                        text: 'Indicadores',
                        active: true,
                        to: '/indicadores',
                    },
                ],
            },
        },
    ]
};


export default routes
