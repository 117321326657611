<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label || "";
              const value = context.value;
              const date = context.dataset.dates[context.index];
              return `${label}: ${value} em ${date}`;
            },
          },
        },
      }),
    },
  },
  computed: {
    renderedChartData() {
      return {
        ...this.chartData,
      };
    },
  },
  watch: {
    renderedChartData(newChartData) {
      this.renderChart(newChartData, this.chartOptions);
      this.$refs.barChart.addEventListener('click', this.onChartClick);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>
