<template>
  <div>
    <div class="main-content card px-1 pb-1" v-if="loader === 100">
      <div class="row p-1 m-0">
        <div class="col-md-12">
          <div class="btn-group w-100" role="group" aria-label="Basic example">
            <button type="button" class="btn" :class="barra ? 'btn-primary' : 'btn-outline-primary'" @click="getGraficoBarra()">Graficos de barra</button>
            <button type="button" class="btn" :class="pizza ? 'btn-primary' : 'btn-outline-primary'" @click="getGraficoPizza()">Graficos de pizza</button>
            <button type="button" class="btn" :class="linha ? 'btn-primary' : 'btn-outline-primary'" @click="getGraficoLinha()">Graficos de linha</button>
            <!-- <button type="button" class="btn" :class="radar ? 'btn-primary' : 'btn-outline-primary'" @click="getGraficoRadar()">Graficos radar</button> -->
          </div>
        </div>
        <div class="col-md-12 text-center mt-2" v-if="radar">
          <h3></h3>
        <!-------------------------------------------------------- Radar ------------------------------------------------------->
          <RadarChart
            :chartData="graficoRadar"
            :chartOptions="graficoRadarOptions"
          />
        </div>
        <!----------------------------------------------------- Pizza Evasão --------------------------------------------------->
        <div class="col-md-6 text-center mt-1" v-show="pizza">
          <h3>Taxa de evasão</h3>
          <PieChart
            :chartData="graficoEvasao"
            :chartOptions="graficoEvasaoOptions"
          />
        </div>
        <!--------------------------------------------------- Pizza Demandas --------------------------------------------------->
        <div class="col-md-6 text-center mt-1" v-show="pizza">
          <h3>Distribuição de demandas</h3>
          <PieChart
            :chartData="graficoDemandas"
            :chartOptions="graficoDemandasOptions"
          />
        </div>
        <!--------------------------------------------------- Pizza Finalizações --------------------------------------------------->
        <div class="col-md-6 text-center mt-1" v-show="pizza">
          <h3>Distribuição das finalizações</h3>
          <PieChart
            :chartData="graficoFinalizacoes"
            :chartOptions="graficoFinalizacoesOptions"
          />
        </div>
        <!--------------------------------------------------- Fluxo por hora  -------------------------------------------------->
        <div class="col-md-12 text-center mt-2" v-if="linha">
          <h3>Fluxo de atendimento por hora</h3>
          <label>Pesquisar por data específica</label>
          <input type="date" class="form-control" v-model="data_fluxo" @input="getAtendimentoHoraEspecifico()" />
          <LineChart
            :chartData="graficoPorHora"
            :chartOptions="graficoPorHoraOptions"
          />
        </div>
        <!-------------------------------------------------------- Setor ------------------------------------------------------->
        <div class="col-md-12 text-center mt-2" v-show="barra">
          <h3>Atendimento por setor</h3>
          <label>Selecionar setor específico</label>
          <v-select v-model="setor" :options="setores" :label="'nome'"
          :reduce="(setores) => setores.id" class="vselect" @input="getGraficoSetorEspecifico()" />
          <BarChart
            ref="atendimentoSetor"
            :chartData="graficoPorSetor"
            :chartOptions="graficoPorSetorOptions"
            @click.native="clickEventAtendimentoSetor"
          />
        </div>
        <!--------------------------------------------------- Classificação ---------------------------------------------------->
         <div class="col-md-12 text-center mt-2" v-show="barra">
          <h3>Atendimento por grupo prioritário</h3>
          <label>Selecionar grupo prioritário específico</label>
          <v-select v-model="classificacao" :options="classificacoes" :label="'nome'"
          :reduce="(classificacoes) => classificacoes.id" class="vselect" @input="getGraficoClassificacaoEspecifica()" />
          <BarChart
            ref="atendimentoClassificacao"
            :chartData="graficoPorClassificacao"
            :chartOptions="graficoPorClassificacaoOptions"
            @click.native="clickEventAtendimentoClassificacao"
          />
        </div>
        <!----------------------------------------------------- Prioridade ----------------------------------------------------->
        <div class="col-md-12 text-center mt-2" v-show="barra">
          <h3>Atendimento por prioridade de atendimento</h3>
          <label>Selecionar prioridade específica</label>
          <v-select v-model="prioridade" :options="prioridades" :label="'nome'"
          :reduce="(prioridades) => prioridades.id" class="vselect" @input="getGraficoPrioridadeEspecifica()" />
          <BarChart
            ref="atendimentoPrioridade"
            :chartData="graficoPorPrioridade"
            :chartOptions="graficoPorPrioridadeOptions"
            @click.native="clickEventAtendimentoPrioridade"
          />
        </div>
        <!-------------------------------------------------------- Modal ------------------------------------------------------->
        <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header bg-primary">
                <h5 class="modal-title text-light" id="modalLabel">{{ labels.nome }} ({{ labels.coluna }})</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <table class="table table-bordered">
                  <thead>
                    <th>ID</th>
                    <th>Senha</th>
                  </thead>
                  <tbody>
                    <tr v-for="atendimento in modal">
                      <td>{{ atendimento.id }}</td>
                      <td>{{ atendimento.atendimento.prefixo }}{{ atendimento.atendimento.senha }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import BarChart from "@/components/BarChart.vue";
import PieChart from "@/components/PieChart.vue";
import LineChart from "@/components/LineChart.vue";
import RadarChart from "@/components/RadarChart.vue";

export default {
  name: "imageUpload",
  data() {
    return {
      labels: {
        coluna: "",
        nome: "",
      },
      modal: [],
      loader: 0,
      barra: 1,
      linha: 0,
      pizza: 0,
      radar: 0,
      data_fluxo: "",
      classificacao: "",
      classificacoes: [],
      prioridade: "",
      prioridades: [
        {
          id:   0,
          nome: "Ausente",
        },
        {
          id:   1,
          nome: "Comum",
        },
        {
          id:   2,
          nome: "Prioritário",
        },
        {
          id:   3,
          nome: "Prioritário +80",
        },
      ],
      setor: "",
      setores: [],
      graficoRadar: { // RADAR
        labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running', 'tesste', 'tesste 2'],
        datasets: [
          {
            label: 'Person A',
            backgroundColor: 'transparent',
            borderColor: '#008800',
            pointBackgroundColor: '#008800',
            pointBorderColor: '#ffffff',
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#008800',
            data: [65, 0, 90, 81, 56, 55, 40, 49, 30],
          },
          {
            label: 'Person B',
            backgroundColor: 'transparent',
            borderColor: '#880000',
            pointBackgroundColor: '#880000',
            pointBorderColor: '#ffffff',
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#880000',
            data: [59, 90, 81, 56, 55, 40, 49, 0, 65],
          }
        ],
      },
      graficoRadarOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      graficoEvasao: { // EVASÃO
        labels: [],
        datasets: [
          {
            label: "Total",
            backgroundColor: "#c0424b",
            data: [],
          },
        ],
      },
      graficoEvasaoOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      graficoDemandas: { // DEMANDAS
        labels: [],
        datasets: [
          {
            label: "Total",
            backgroundColor: "#c0424b",
            data: [],
          },
        ],
      },
      graficoDemandasOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      graficoFinalizacoes: { // FINALIZAÇÕES
        labels: [],
        datasets: [
          {
            label: "Total",
            backgroundColor: "#15488a",
            data: [],
          },
        ],
      },
      graficoFinalizacoesOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      graficoPorHora: { // FLUXO POR HORA
        labels: [],
        datasets: [
          {
            label: "Abertura",
            borderColor: "#4b72a5",
            data: [],
            tension: 0,
          },
          {
            label: "Fechamento",
            borderColor: "#ff2020",
            data: [],
            tension: 0,
          },
        ],
      },
      graficoPorHoraOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
            },
          ],
        },
      },
      graficoPorSetor: { // SETOR
        labels: [],
        datasets: [
          {
            label: "Total",
            backgroundColor: "#999999",
            data: [],
          },
          {
            label: "Em fila",
            backgroundColor: "#4b72a5",
            data: [],
          },
          {
            label: "Finalizados",
            backgroundColor: "#367e43",
            data: [],
          },
        ],
      },
      graficoPorSetorOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
            },
          ],
        },
      },
      graficoPorClassificacao: { // CLASSIFICAÇÃO
        labels: [],
        datasets: [
        {
            label: "Total",
            backgroundColor: "#999999",
            data: [],
          },
          {
            label: "Em fila",
            backgroundColor: "#4b72a5",
            data: [],
          },
          {
            label: "Finalizados",
            backgroundColor: "#367e43",
            data: [],
          },
        ],
      },
      graficoPorClassificacaoOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
            },
          ],
        },
      },
      graficoPorPrioridade: { // PRIORIDADE
        labels: [],
        datasets: [
        {
            label: "Total",
            backgroundColor: "#999999",
            data: [],
          },
          {
            label: "Em fila",
            backgroundColor: "#4b72a5",
            data: [],
          },
          {
            label: "Finalizados",
            backgroundColor: "#367e43",
            data: [],
          },
        ],
      },
      graficoPorPrioridadeOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
            },
          ],
        },
      },
    };
  },
  computed: {},
  methods: {
    getClassificacoes: function(){
      const self = this;
      var api = self.$store.state.api + "classificacoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.classificacoes = response.data.data;

          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function(){
      const self = this;
      var api = self.$store.state.api + "setores?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;

          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // - - - - - - - - - Z- - - - - -   GRAFICOS   - - - - - - - - - - - - - - - - - - -
    getEvasao: function(){
      const self = this;
      const api = self.$store.state.api + "indicador-evasao/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoEvasao.labels = items.labels.map(
            (item) => item
          );
          self.graficoEvasao.datasets[0].data = items.valores.map(
            (item) => item
          );
          self.graficoEvasao.datasets[0].backgroundColor = items.background.map(
            (item) => item
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getDemandas: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-demandas/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoDemandas.labels = items.botao.map(
            (item) => item
          );
          self.graficoDemandas.datasets[0].data = items.qtd.map(
            (item) => item
          );
          self.graficoDemandas.datasets[0].backgroundColor = items.cor.map(
            (item) => item
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getFinalizacoes: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-finalizacoes/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoFinalizacoes.labels = items.finalizacao.map(
            (item) => item
          );
          self.graficoFinalizacoes.datasets[0].data = items.qtd.map(
            (item) => item
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getAtendimentoHora: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-atendimento-hora/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoPorHora.labels = items.labels.map(
            (item) => item
          );
          self.graficoPorHora.datasets[0].data = items.abertura.map(
            (item) => item
          );
          self.graficoPorHora.datasets[1].data = items.fechamento.map(
            (item) => item
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getAtendimentoHoraEspecifico: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-atendimento-hora-dia/";

      self.$loading(true);

      axios
        .post(api, {data: self.data_fluxo})
        .then((response) => {
          let items = response.data;

          self.graficoPorHora.labels = items.labels.map(
            (item) => item
          );
          self.graficoPorHora.datasets[0].data = items.abertura.map(
            (item) => item
          );
          self.graficoPorHora.datasets[1].data = items.fechamento.map(
            (item) => item
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getGraficoSetorEspecifico: function(){ // Setor ------------------------------------------------------------------------
      const self = this;

      self.$loading(true);

      if(self.setor)
      {
        const api = self.$store.state.api + "indicador-atendimento-setor/" + self.setor;

        axios
          .get(api)
          .then((response) => {
            let items = response.data;

            self.graficoPorSetor.labels = items.map(
              (item) => item.setor
            );
            self.graficoPorSetor.datasets[0].data = items.map(
              (item) => item.total
            );
            self.graficoPorSetor.datasets[1].data = items.map(
              (item) => item.fila
            );
            self.graficoPorSetor.datasets[2].data = items.map(
              (item) => item.finalizados
            );

            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            self.$message(null, error.response.data, "error");
          });

          return;
      }

      self.$loading(false);
      self.getGraficoSetor();
    },
    getGraficoSetor: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-atendimento-setores/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoPorSetor.labels = items.map(
            (item) => item.setor
          );
          self.graficoPorSetor.datasets[0].data = items.map(
            (item) => item.total
          );
          self.graficoPorSetor.datasets[1].data = items.map(
            (item) => item.fila
          );
          self.graficoPorSetor.datasets[2].data = items.map(
            (item) => item.finalizados
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    clickEventAtendimentoSetor: function(event) {
      const self = this;

      if (!self.$refs.atendimentoSetor) return;

      const chartInstance = self.$refs.atendimentoSetor.$data._chart;
      const activePoints = chartInstance.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        true
      );

      if (activePoints.length) {
        self.$loading(true);

        const firstPoint = activePoints[0];
        const label = firstPoint._model.label;
        const index = firstPoint._model.datasetLabel;

        self.labels.nome   = label;
        self.labels.coluna = index;

        const api = self.$store.state.api + "click-setor/";

        axios
          .post(api, self.labels)
          .then((response) => {
            self.modal = response.data;
            self.$loading(false);
            $('#modal').modal('toggle');
          });
      }
    },
    getGraficoClassificacaoEspecifica: function(){ // Classificação ------------------------------------------------------------------
      const self = this;

      self.$loading(true);

      if(self.classificacao)
      {
        const api = self.$store.state.api + "indicador-atendimento-classificacao/" + self.classificacao;

        axios
          .get(api)
          .then((response) => {
            let items = response.data;

            self.graficoPorClassificacao.labels = items.map(
              (item) => item.classificacao
            );
            self.graficoPorClassificacao.datasets[0].data = items.map(
              (item) => item.total
            );
            self.graficoPorClassificacao.datasets[1].data = items.map(
            (item) => item.fila
            );
            self.graficoPorClassificacao.datasets[2].data = items.map(
              (item) => item.finalizados
            );

            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            self.$message(null, error.response.data, "error");
          });

          return;
      }

      self.$loading(false);
      self.getGraficoClassificacao();
    },
    getGraficoClassificacao: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-atendimento-classificacoes/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoPorClassificacao.labels = items.map(
            (item) => item.classificacao
          );
          self.graficoPorClassificacao.datasets[0].data = items.map(
            (item) => item.total
          );
          self.graficoPorClassificacao.datasets[1].data = items.map(
          (item) => item.fila
          );
          self.graficoPorClassificacao.datasets[2].data = items.map(
            (item) => item.finalizados
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    clickEventAtendimentoClassificacao: function(event) {
      const self = this;

      if (!self.$refs.atendimentoClassificacao) return;

      const chartInstance = self.$refs.atendimentoClassificacao.$data._chart;
      const activePoints = chartInstance.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        true
      );

      if (activePoints.length) {
        self.$loading(true);

        const firstPoint = activePoints[0];
        const label = firstPoint._model.label;
        const index = firstPoint._model.datasetLabel;

        self.labels.nome   = label;
        self.labels.coluna = index;

        const api = self.$store.state.api + "click-classificacao/";

        axios
          .post(api, self.labels)
          .then((response) => {
            self.modal = response.data;
            self.$loading(false);

            $('#modal').modal('toggle');
          });
      }
    },
    getGraficoPrioridadeEspecifica: function(){ // Prioridade -----------------------------------------------------------------------------------------
      const self = this;

      self.$loading(true);

      if(self.prioridade || self.prioridade === 0)
      {
        const api = self.$store.state.api + "indicador-atendimento-prioridade/" + self.prioridade;

        axios
          .get(api)
          .then((response) => {
            let items = response.data;

            self.graficoPorPrioridade.labels = items.map(
              (item) => item.prioridade
            );
            self.graficoPorPrioridade.datasets[0].data = items.map(
              (item) => item.total
            );
            self.graficoPorPrioridade.datasets[1].data = items.map(
            (item) => item.fila
            );
            self.graficoPorPrioridade.datasets[2].data = items.map(
              (item) => item.finalizados
            );

            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            self.$message(null, error.response.data, "error");
          });

          return;
      }

      self.$loading(false);
      self.getGraficoPrioridade();
    },
    getGraficoPrioridade: function() {
      const self = this;
      const api = self.$store.state.api + "indicador-atendimento-prioridades/";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          let items = response.data;

          self.graficoPorPrioridade.labels = items.map(
            (item) => item.prioridade
          );
          self.graficoPorPrioridade.datasets[0].data = items.map(
            (item) => item.total
          );
          self.graficoPorPrioridade.datasets[1].data = items.map(
          (item) => item.fila
          );
          self.graficoPorPrioridade.datasets[2].data = items.map(
            (item) => item.finalizados
          );

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    clickEventAtendimentoPrioridade: function(event) {
      const self = this;

      if (!self.$refs.atendimentoPrioridade) return;

      const chartInstance = self.$refs.atendimentoPrioridade.$data._chart;
      const activePoints = chartInstance.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        true
      );

      if (activePoints.length) {
        self.$loading(true);

        const firstPoint = activePoints[0];
        const label = firstPoint._model.label;
        const index = firstPoint._model.datasetLabel;

        self.labels.nome   = label;
        self.labels.coluna = index;

        const api = self.$store.state.api + "click-prioridade/";

        axios
          .post(api, self.labels)
          .then((response) => {
            self.modal = response.data;
            self.$loading(false);

            $('#modal').modal('toggle');
          });
      }
    },
    getGraficoBarra: function(){ // Chamadas dos gráficos --------------------------------------------------------------
      const self = this;

      self.barra = 1;
      self.pizza = 0;
      self.linha = 0;
      self.radar = 0;
      self.getGraficoSetor();
      self.getGraficoClassificacao();
      self.getGraficoPrioridade();
    },
    getGraficoPizza: function(){
      const self = this;

      self.barra = 0;
      self.pizza = 1;
      self.linha = 0;
      self.radar = 0;
      self.getEvasao();
      self.getDemandas();
      self.getFinalizacoes();
    },
    getGraficoLinha: function(){
      const self = this;

      self.barra = 0;
      self.pizza = 0;
      self.linha = 1;
      self.radar = 0;
      self.getAtendimentoHora();
    },
    getGraficoRadar: function(){
      const self = this;

      self.barra = 0;
      self.pizza = 0;
      self.linha = 0;
      self.radar = 1;

    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;

    // self.setLoader(100);
    self.setLoader(50);

    self.getGraficoBarra();

    self.getSetores();        // loader 25
    self.getClassificacoes(); // loader 25
  },
  components: {
    BaseCrud,
    BarChart,
    PieChart,
    LineChart,
    RadarChart,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>