import Crud from '../views/Senha/Crud'
import Entity from '../views/Senha/Path'

let routes = {
  path: '/senhas',
  component: resolve => require(['../layouts/full/LayoutFull'], resolve),
  children: [
    {
      path: '/senhas',
      name: 'list-senhas',
      component: Crud,
      meta: {
        pageTitle: 'Lista de Senhas',
        breadcrumb: [
          {
              text: 'Senhas',
              active: true,
          },
        ],
      },
    },
    {
      path: '/senhas/path/:id?',
      name: 'PathSenha',
      component: Entity,
      meta: {
        pageTitle: 'Caminho da Senha',
        breadcrumb: [
          {
            text: 'Senhas',
            active: false,
            to: '/senhas',
          },
          {
            text: 'Caminho',
            active: true,
          }
        ],
      },
    },
  ]
};


export default routes
