let env = {};

env.baseURL = 'https://api.novo-sigap.ares-saude.com.br/api/v1/';
env.normalURL = 'https://api.novo-sigap.ares-saude.com.br/';

env.webserviceHost = 'websocket.novo-sigap.ares-saude.com.br';
env.webservicePort = '';

//env.mode = 'homolog';
//env.mode = 'production';
//env.mode = 'development';

export default env
