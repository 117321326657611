<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{ configuracao.id ? "Editar" : "Adicionar" }}
                Configuração
              </div>
            </div>
            <div class="card-body">
     
              <div class="row">
                <div class="col md-12 mb-2">
                  <label>Nome dado aos usuários (ex. paciente, pessoa, vítima)</label>
                  <input
                      type="text"
                      class="form-control"
                      v-model="configuracao.tipo_pessoa"
                    />
                </div>
                <div class="col-md-12">
                  <div class="form-group col-md-6">
                    <div class="custom-control custom-switch">
                      <input
                        autocomplete="new-password"
                        v-model="configuracao.painel"
                        placeholder=""
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                      />
                      <label class="custom-control-label" for="customSwitch1"
                        >Exibir painel</label
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <div class="custom-control custom-switch">
                      <input
                        autocomplete="new-password"
                        v-model="configuracao.cns"
                        placeholder=""
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch2"
                      />
                      <label class="custom-control-label" for="customSwitch2"
                        >Exibir cns</label
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4 d-flex align-items-center">
                    <div class="custom-control custom-switch">
                      <input
                        autocomplete="new-password"
                        v-model="configuracao.classifica_risco"
                        placeholder=""
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch3"
                      />
                      <label class="custom-control-label" for="customSwitch3"
                        >Classifica Risco</label
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-4 d-flex align-items-center">
                    <div class="custom-control custom-switch">
                      <input
                        autocomplete="new-password"
                        v-model="configuracao.integra_ares_triagem"
                        placeholder=""
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch4"
                      />
                      <label class="custom-control-label" for="customSwitch4"
                        >Intrega com Ares Triagem?</label
                      >
                    </div>
                  </div>
                  <div
                    class="form-group col-md-4 mb-2"
                    v-if="configuracao.integra_ares_triagem == 1"
                  >
                    <label>URL</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="configuracao.url_triagem"
                    />
                  </div>

                  <div class="block text-right">
                    <router-link to="/configuracoes" class="btn btn-back mr-1">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      configuracao: {
        tipo_pessoa: "",
        painel: 0,
        cns: 0,
        integra_ares_triagem: 0,
        url_triagem: "",
        envia_ares_triagem: 0,
      },
    };
  },
  computed: {},
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("painel",               self.configuracao.painel               ? "1" : "0");
      fd.append("cns",                  self.configuracao.cns                  ? "1" : "0");
      fd.append("classifica_risco",     self.configuracao.classifica_risco     ? "1" : "0");
      fd.append("integra_ares_triagem", self.configuracao.integra_ares_triagem ? "1" : "0");
      fd.append("envia_ares_triagem",   self.configuracao.envia_ares_triagem   ? "1" : "0");
      fd.append("url_triagem",          self.configuracao.url_triagem ? self.configuracao.url_triagem : "");
      fd.append("tipo_pessoa",          self.configuracao.tipo_pessoa ? self.configuracao.tipo_pessoa : "Paciente");

      fd.append("_method", "POST");

      if (self.configuracao.id) {
        fd.append("id", self.configuracao.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes";

      let fd = self.makeFormData();

      if (self.configuracao.id) {
        api += "/" + self.configuracao.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/configuracoes");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConfiguracao: function (id) {
      const self = this;
      const api = self.$store.state.api + "configuracoes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
          self.configuracao.painel = self.configuracao.painel == "0" ? 0 : 1;
          self.configuracao.cns = self.configuracao.cns == "0" ? 0 : 1;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getConfiguracao(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}
</style>