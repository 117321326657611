import Crud from '../views/Botao/Crud'
import Entity from '../views/Botao/Add'

let routes = {
    path: '/botoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/botoes/add',
            name: 'NewBotao',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Botão',
                breadcrumb: [
                    {
                        text: 'Botões',
                        active: false,
                        to: '/botoes',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/botoes/edit/:id?',
            name: 'EditBotao',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Botão',
                breadcrumb: [
                    {
                        text: 'Botões',
                        active: false,
                        to: '/botoes',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/botoes',
            name: 'list-botoes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Botões',
                breadcrumb: [
                    {
                        text: 'Botões',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
