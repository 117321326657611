<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <base-crud
                :ref="'botoesCrud'"
                crudName="Botões"
                newText="Novo Botão"
                :table="table"
                :columns="columns"
                :options="options"
                :endPoint="endPoint"
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableActive="true"
                :enableView="false"
                :enableReset="false"
                :enableSenhaPath="false"
                :dataForm="dataForm"
                >
                </base-crud>
            </div>
        </div>
    </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "botoesTable",
            showMessageImport: false,
            usersCrud: {},
            pages: [],
            url: "",
            columns: ["id", "nome", "setor_id", "preferencial", "prefixo_senha", "ordem", "cor", "actions"],
            tableData: ["id", "nome", "setor_id", "preferencial", "prefixo_senha", "ordem", "cor"],
            options: {
                filterByColumn: true,
                debounce: 1000,
                filterable: ["nome"],
                pagination: { chunk: 10, dropdown: false, nav: "scroll" },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: "ID",
                    nome: "Nome",
                    setor_id: "Setor",
                    prefixo_senha: "Prefixo",
                    preferencial: "Prioridade",
                    cor: "Cor",
                    actions: "Ações",
                },

                texts: {
                    filterBy: "Filtrar",
                    defaultOption: "Selecione",
                },
                listColumns: {
                    preferencial: [
                        {
                            id: 1,
                            text: "Comum",
                        },
                        {
                            id: 2,
                            text: "Prioridade Básica",
                        },
                        {
                            id: 3,
                            text: "Alta Prioridade",
                        },
                    ],
                },
                templates: {
                    setor_id: function (h, row, index) {
                        return row.setor ? row.setor.nome : "Indisponível";
                    }
                },
                requestFunction: function (data) {
                    let requestData = {};

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";
                    requestData.params.with = ["setor"];

                    return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "botoes/",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    methods: {
        query: function (query) {
            let columns = {
                id: "id",
                nome: "nome",
                setor_id: "setor[nome]",
                cor: "cor",
            };
        let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },
    },
};
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
    .uploading-image {
        display: flex;
        border-radius: 10px;
        width: 100px;
        height: 100px;
    }
</style>