import Chamador from '../views/Chamador/Chamador'
import Atender from '../views/Chamador/Atender'

let routes = {
    path: '/classificacaorisco',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/chamador',
            name: 'chamador',
            component: Chamador,
            meta: {
                pageTitle: 'Chamador',
                breadcrumb: [
                    {
                        text: 'Chamador',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/atender/:id?',
            name: 'atender',
            component: Atender
        },
    ]
};


export default routes
