import Crud from '../views/Classificacao/Crud'
import Entity from '../views/Classificacao/Add'

let routes = {
    path: '/classificacoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/classificacoes/add',
            name: 'NewClassificacoes',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Grupo Prioritário',
                breadcrumb: [
                    {
                        text: 'Grupo Prioritário',
                        active: false,
                        to: '/classificacoes',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/classificacoes/edit/:id?',
            name: 'EditClassificacoes',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Grupo Prioritário',
                breadcrumb: [
                    {
                        text: 'Grupo Prioritário',
                        active: false,
                        to: '/classificacoes',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/classificacoes',
            name: 'list-classificacoes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de  Grupos Prioritários',
                breadcrumb: [
                    {
                        text: 'Grupo Prioritário',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
